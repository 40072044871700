import React from 'react';
import footer_styles from './footer.module.css';
import { Link } from 'react-router-dom';
import Stamp from './stamp';

const Footer = () => {

    return (
      <div>
        <svg viewbox="0 0 0 0" width="0" height="0">
          <defs>
            <filter id="noise">
              <feTurbulence type="fractalNoise" baseFrequency="0.1" numOctaves="10" stitchTiles="stitch" />
              <feColorMatrix type="matrix" values="0.33 0.33 0.33 0 0  0.33 0.33 0.33 0 0  0.33 0.33 0.33 0 0  0 0 0 1 0" />
              <feComponentTransfer>
                <feFuncR type="linear" slope="2" />
                <feFuncG type="linear" slope="2" />
                <feFuncB type="linear" slope="2" />
                <feFuncA type="linear" slope="0.5" />

              </feComponentTransfer>
              <feComposite operator="in" in2="SourceAlpha" result="composite"/>
              <feBlend in="SourceGraphic" in2="composite" mode="color-burn" />
            </filter>
          </defs>
        </svg>
        <div className={footer_styles.footer_div}>
          <div style={{filter: 'url(#noise)', opacity: 0.3, marginTop: '-100px', display: 'flex',alignItems: 'center',justifyContent:'center', transform: 'rotate(-25deg)', margin: '0 auto', width: '250px', height:  '250px'}}>
            <Stamp />
          </div>
          <div className={footer_styles.footer_bottom_div}>
            <p class='a' style={{border: 'none'}}>© 2025 Sail LLC. All rights reserved. </p>
            <div style={{display: "flex", gap:0}}>
              <Link to="/contact" class='a' style={{margin:0,marginRight: "10px", borderRadius: 0, height: '20px'}}>Contact</Link>
              <Link to="/terms-of-service" class='a' style={{margin:0,marginRight: "10px", borderRadius: 0, height: '20px'}}>Terms of Service</Link>
              <Link to="/privacy-policy" class='a' style={{margin:0,borderRadius: 0, height: '20px', border: 'none'}}>Privacy Policy</Link>
            </div>    
          </div>
        </div>
        
      </div>
    );
};

export default Footer;
