import React from "react";
import styles from "./stamp.module.css";
import Logo from "../assets/logo_gray.svg";

const createArcText = (text, radius, isTop, angleDeg, offset) => {
  const characters = text.split("").reverse();
  const angle = angleDeg / (characters.length - 1);
  return characters.map((char, i) => {
    const rotation = isTop ? -angle * i : angle * i;
    return (
      <span
        key={i}
        className={styles.letter}
        style={{
          transform: `rotate(${rotation}deg) translate(${radius}px) rotate(${-rotation + offset}deg)`,
          height: "20px",
            width: "20px",
            fontSize: "20px",
            lineHeight: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
      >
        {char}
      </span>
    );
  });
};

const Stamp = () => {
  return (
    <div className={styles.circle}>
      <div style={{transform: 'translateY(0px) translateX(-20px) rotate(-45deg) '}} >
        {createArcText("SAIL", 100, true, 90, 45)}
      </div>
      <div style={{transform: 'translate(-10px)'}}>
        {createArcText("VACATIONS", 100, false, 180, 0)}
      </div>
      <img src={Logo} alt="Sail Logo" style={{width: '175px', border: '1px solid rgb(139, 139, 139)', borderRadius: "50%"}}/>
    </div>
  );
};

export default Stamp;