import React from 'react';
import first_class from "./first_class_details.module.css"
import Detail from "./detail";
import suggestions_picture from "../assets/suggestions_picture.webp";
import calendar_integrations from "../assets/calendar.webp";
import packinglist from "../assets/packinglist.webp";

const FirstClassDetails = () => {

    const details = {
        "detail_1": ["Intelligent Itinerary", "The most intelligent travel guide now fits in your pocket. The AI enabled suggestions fill your itinerary no matter the place and time.", true, suggestions_picture],
        "detail_2": ["Calendar Connections", "Keep your schedule organized by integrating Sail with you favorite calendar.", false, calendar_integrations],
        "detail_3": ["Smart Packing List", "Whether it's your skis or your snorkle, never forget anything at home again. The smart packing list will intelligently suggest items to pack based on your itinerary.", false, packinglist],
    }

    return (
        <div style={{backgroundColor: "black", width: "100vw"}}>
            <div className={first_class.firstClassDetails}>
                {Object.keys(details).map((key) => (
                    <Detail detail={details[key]} />
                ))}
            </div>
        </div>
    );
}

export default FirstClassDetails;
