import React from 'react';
import Header from './header';

const TermsOfService = () => {

    return (
          <div>
          <Header/>
          <div className='privacy-policy' style={{textAlign:"left", padding:"20px", maxWidth:"600px", margin: "auto"}}>
          <h1 type="title" style={{fontSize:"50px", textAlign:"center", lineHeight: '75px'}}>Terms of Service</h1>
          <h3><b>Introduction</b></h3>
          <br />
          <b>Last updated: 8/2024 </b>
          <br />
          <br />
                Welcome to Sail Vacations, a Sail LLC app. By accessing or using our application, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.
                <br />
                <br />
                        <b>Acceptance of Terms</b>
                        <br />
                        <br />  By using the Sail application, you agree to these Terms of Service. If you do not agree to these terms, please do not use our application.
                <br />
                <br />
                        <b>Use of the Application</b>
                <br />
                <br />
                You must be at least 18 years old to use the Sail application. By using the application, you represent and warrant that you meet this age requirement.
                <br />
                <br />
                <b>License</b>
                <br />
                Sail LLC grants you a limited, non-exclusive, non-transferable, and revocable license to use the Sail application for personal, non-commercial purposes.
                <br />
                <br />
                <b>Prohibited Conduct</b>
                <br />
                You agree not to:
                <ul>
                <li>Use the application for any illegal or unauthorized purpose.</li>
                <li>Interfere with or disrupt the operation of the application.</li>
                <li>Attempt to gain unauthorized access to any part of the application or its related systems or networks.</li>
                <li>Use the application to transmit any harmful or malicious code.</li>
                </ul>
                <br />
                        <b>Location Data</b>
                <b> Usage</b>
                <br />
                To provide you with the core functionality of Sail, we use, but do not store, your location data. This data is used solely to identify points of interest in a middle area between your current location and another location you specify.
                <br />
                <br />
                <b>Data Security</b>
                <br />
                We implement robust security measures to protect your location data during transmission. Our application uses encryption protocols to ensure that your data is secure and protected from unauthorized access.
                <br />
                <br />
                <b>No Data Retention</b>
                <br />
                We do not store your location data. Once the necessary calculations are performed to find the middle area points of interest, your location data is discarded.
                <br />
                <br />
                        <b>Third-Party Services</b>
                <br />
                The Sail application may use third-party services, such as Apple Maps, to provide certain functionalities. We do not share your location data with any third-party services. Your privacy is of utmost importance to us, and we ensure that your data is used exclusively within the Sail application for the purposes described in this policy.
                <br />
                <br />
                        <b>User Rights</b>
                <br />
                Since we do not store your location data, there is no stored information for you to access, modify, or delete. You have full control over your location data by choosing when to provide it to the application.
                <br />
                <br />
                        <b>Changes to These Terms</b>
                <br />
                Sail LLC reserves the right to update these Terms of Service at any time. We will notify you of any changes by posting the new Terms of Service on this page. You are advised to review these Terms of Service periodically for any changes.
                <br />
                <br />
                        <b>Limitation of Liability</b>
                <br />
                To the fullest extent permitted by law, Sail LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
                <ul>
                <li>Your use or inability to use the application.</li>
                <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
                <li>Any interruption or cessation of transmission to or from the application.</li>
                <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our application by any third party.</li>
                </ul>
                <br />
                        <b>Governing Law</b>
                <br />
                These Terms of Service shall be governed by and construed in accordance with the laws of the state in which Sail LLC is headquartered, without regard to its conflict of law principles.
                <br />
                <br />
                        <b>Contact Us</b>
                <br />
                If you have any questions about these Terms of Service, please contact us at support@sailvacations.app.
                <br />
                <br />
                By using the Sail application, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
                <br />
                <br />
                </div>
          </div>

    );
  };
  
  export default TermsOfService