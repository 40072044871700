import React, { useRef, useState, useEffect, useContext } from 'react';
import hero_styles from './hero.module.css';
import Toolbar from '../../components/toolbar';
// import hero from '../../assets/hero.png';
// import AppStore from '../../assets/appstore.svg';
import RotateWord from '../../components/rotate_word';
import { ArrowRight } from 'lucide-react';
import { DarkModeContext } from '../../DarkModeProvider';
import hero3 from '../../assets/hero3.webp';
import ScrollHero from '../features/scroll_hero';
import styles from '../index.module.css';
import zIndex from '@mui/material/styles/zIndex';



const Hero = ({ onFeaturesClick, onFirstClassClick }) => {
  const logoDivRef = useRef(null);
  const titleH1Ref = useRef(null);
  const buttonDivRef = useRef(null);
  const requestRef = useRef(null);
  const [totalHeight, setTotalHeight] = useState(0);
  const { isDarkMode } = useContext(DarkModeContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleScroll = () => {
        const logoDivHeight = logoDivRef.current ? logoDivRef.current.offsetHeight : 0;
        const titleH1Height = titleH1Ref.current ? titleH1Ref.current.offsetHeight : 500;
        const buttonDivHeight = buttonDivRef.current ? buttonDivRef.current.offsetHeight : 0;

        const total = logoDivHeight + titleH1Height + buttonDivHeight;
        setTotalHeight(total);
        console.log('total height', total);
    };

    const throttledHandleScroll = () => {
        if (!requestRef.current) {
            requestRef.current = requestAnimationFrame(() => {
                handleScroll();
                requestRef.current = null;
            });
        }
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('scroll', throttledHandleScroll);
    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 768);

    return () => {
        window.removeEventListener('scroll', throttledHandleScroll);
        window.removeEventListener('resize', handleResize);
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }
    };
  }, []);


  const words = [
    'Organize your itinerary.', 
    'Collaborate with friends.', 
    'Plan your dream vacations.', 
    'Find your community.', 
    'Explore new destinations.',
  ];

  return (
    <div className={hero_styles.container}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 3}}>
          <Toolbar index={true} totalHeight={totalHeight} onFeaturesClick={onFeaturesClick} onFirstClassClick={onFirstClassClick}/>
          <h1 ref={titleH1Ref} type="title">The Ultimate <br /> Travel Companion</h1>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 3, marginTop: "50px", gap: "10px"}}>
            <a className={hero_styles.ctaButton} style={{

            backgroundColor: !isDarkMode ? "#1a1a1a" : "#FCFCFD",
            boxShadow: !isDarkMode ? 'rgba(45, 35, 66, 0.4) 0 2px 4px, #00000070 0 7px 13px -3px, #00000040 0 -3px 3px inset, #ffffff90 0 3px 2px inset' : 'rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 3px inset',
            color: !isDarkMode ? "white" : '#36395A',

            }} href="https://apps.apple.com/us/app/sail-vacations-social-travel/id6471536080?ign-itscg=30200&ign-itsct=apps_box_link&mttnsubad=6471536080">
              <p style={{fontWeight: "500", color: isDarkMode ? "black" :  'white', fontSize: !isMobile ? "12pt" : "8pt"}}>Try Sail on iOS </p><ArrowRight/>
            </a>
            <div className={hero_styles.ctaButton2} style={{
              backgroundColor: isDarkMode ? "#1f1f1f" : "white", 
              boxShadow: isDarkMode ? 'rgba(45, 35, 66, 0.4) 0 2px 4px, #00000070 0 7px 13px -3px, #00000040 0 -3px 3px inset' : 'rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 3px inset, #ffffff90 0 3px 2px inset',
            }}>
                <p style={{
                  fontWeight: "500", 
                  margin: 0, 
                  color: isDarkMode ? "white" :  'black',
                  fontSize: !isMobile ? "12pt" : "8pt"}}>
                    Android and Web.</p>
                <p style={{ 
                  color: '#3b3116', 
                  position: 'absolute', 
                  bottom: '-30px', 
                  backgroundColor: '#fff1c790',
                  border: '1px solid #FFC921', 
                  borderRadius: '25px', 
                  padding: '0px 7px', 
                  fontSize:"8pt",
                  backdropFilter: 'blur(10px)' 
                }}><b>COMING SOON</b></p>
            </div>
          </div>
          
          
          {/* <a style={{display: 'flex', alignItems: "center", marginBottom: "50px", width:'250px'}} href="https://apps.apple.com/us/app/sail-vacations-social-travel/id6471536080?ign-itscg=30200&ign-itsct=apps_box_link&mttnsubad=6471536080">
            <img src={AppStore} alt="sail_logo" style={{filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2))", margin: '0px', width: "100%"}} />
          </a> */}
          
          
        {/* <img src={hero}  alt="Sail Hero"/> */}
        <img loading="eager" rel="preload" as="image" width={500} height={500} src={hero3}  alt="Sail Hero" className={hero_styles.heroImage}/>
          {!isMobile && (
              <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100vw", height: '100vh'}}>

                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <ScrollHero className={styles.hideOnMobile}/>
                </div>
                <RotateWord phrases={words} style={{ position: 'absolute', zIndex: '3' }}/>
            </div>
          )}
          
                
      </div>
    </div>
  );
};

export default Hero;
