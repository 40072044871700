import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
// import hero3 from "./assets/hero3.webp";


function Layout({ children }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tripId = searchParams.get("trip");

  const ogImageUrl = tripId
    ? `https://trips.sailvacations.app/api/og?id=${tripId}`
    : "https://storage.googleapis.com/sailog/ad.png";

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Sail Vacations (from layout)" />
        <meta property="og:description" content="Sail Vacations: The Social Travel App" />
        <meta property="og:image" content={ogImageUrl} />
        <meta property="og:url" content={`https://sailvacations.app/trip?t=${tripId}`} />
        <link rel="preload" href="./assets/hero3.webp" as="image" type="image/webp" />

      </Helmet>
      {children}
    </>
  );
}

export default Layout;