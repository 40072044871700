import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import styles from './contact.module.css';
import { getDatabase, ref, push } from 'firebase/database'; // Import necessary functions
import { app } from './firebaseConfig'; // Import your Firebase app

const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const database = getDatabase(app); // Initialize the database

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    try {
      await push(ref(database, 'contacts'), {
        email,
        message,
      });
      console.log('Data written to database');
      navigate('/');
    } catch (error) {
      console.error('Error writing to database', error);
    }
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={`${styles.privacyPolicy}`}>
        <h1 type="title" className={styles.title}>Contact Us</h1>
        <h3><b>Support Form</b></h3>
        <br />
        <form onSubmit={handleSubmit} className={styles.form}>
          <b>Email:</b>
          <br />
          <input
            type="text"
            className={styles.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <br />
          <b className={styles.marginTop}>What can we help with?</b>
          <br />
          <textarea
            className={styles.textarea}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button type='submit' className={styles.submitButton}>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;