import React, { createContext, useState, useEffect } from 'react';

export const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    const className = 'dark';
    const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
    
    if (isDarkMode) {
      document.body.classList.add(className);
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute('content', '#0f0f0f');
      }
    } else {
      document.body.classList.remove(className);
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute('content', '#ffffff');
      }
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};