import React, { useEffect, useState } from 'react';
import styles from './scrollhero.module.css'; // Import the CSS file for styling
import hero from '../../assets/hero2.webp';
import phone from '../../assets/phone.webp';
import { motion, useScroll } from "motion/react"

const ScrollHero = () => {
  const isMobile = window.innerWidth <= 768;
  const { scrollYProgress } = useScroll()

  // Convert scrollYProgress to a readable value
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setScrollProgress(latest);
    });
  }, [scrollYProgress]);

  return (
    <div className={styles.scrollHero} style={{ position: 'relative' }}>
      <img 
        style={{
          position: 'absolute',
          top: -200,
          width: `${Math.max(-scrollProgress * 5700 + 480, 230)}px`,
          borderRadius: `${Math.min(scrollProgress*1000 + 5, 45)}px`,
          height: `495px`,
          objectFit: "cover",
          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.4)", 
          border: "2px solid rgba(255, 255, 255, 0.2)"
        }}
        src={hero} 
        alt="Background" 
        // className={styles.scrollHeroImg}
      />
      <img 
        style={{ 
          position: 'absolute',
          top: -200,
          opacity: `${scrollProgress > 0.043 ? 1 : 0}`,
          height: "500px", 
          width: "auto", 
          transition: "opacity 0.3s ease-in-out"
        }}
        src={phone} 
        alt="Background" 
        className={styles.scrollHeroImg}
      />
    </div>
  );
};

export default ScrollHero;
